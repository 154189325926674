import { Controller } from 'stimulus';

export default class extends Controller {
  toggleShowArchived(e) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('show_archived_stock', e.currentTarget.checked === true);
    window.location.search = urlParams;
  }

  locationSelected(e) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('location_id', e.currentTarget.value);
    window.location.search = urlParams;
  }
}
